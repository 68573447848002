export default [
  {
    path: '/devices',
    name: 'devices',
    component: () => import('@/views/devices/index.vue'),
    meta: {
      pageTitle: 'Devices',
      breadcrumb: [
        {
          text: 'Devices',
          active: true,
        },
      ],
      requireAuth: true,
    },
  },
  {
    path: '/device/:id/:deviceName',
    name: 'single-device-user',
    component: () => import('@/views/devices/SingleDevice.vue'),
    meta: {
      pageTitle: '',
      breadcrumb: [
        {
          text: 'Devices',
          to: '/devices',
        },
        {
          text: '',
          active: true,
        },
      ],
      requireAuth: true,
    },
    beforeEnter(to, from, next) {
      if (to.name === 'single-device-user') {
      /* eslint-disable */
        to.meta.pageTitle = to.params.deviceName
        to.meta.breadcrumb[1].text=to.params.deviceName
        /* eslint-enable */
        next()
      }
    },
  },

]
