import Vue from 'vue'
import { showToast } from '@/common/global/functions'

export default {
  namespaced: true,
  state: {
    allDevices: [],
    singleDevice: {},
    allGroups: [],
    macAddress: '',
    hivestackSSpData: {},
    vistarSSpData: {},
    dummyImages: [],
    tabState: null,
  },
  mutations: {
    SET_DEVICES(state, payload) {
      state.allDevices = [...payload]
    },
    SET_DUMMY_IMAGES(state, payload) {
      state.dummyImages = payload
    },
    SET_DEVICE(state, payload) {
      state.singleDevice = { ...payload }
    },
    SET_GROUPS(state, payload) {
      state.allGroups = [...payload]
    },
    SET_MAC_ADDRESS(state, payload) {
      state.macAddress = payload
    },
    SET_HIVESTACK(state, payload) {
      state.hivestackSSpData = { ...payload }
    },
    SET_VISTAR(state, payload) {
      let changeCpm = payload.cpm_floor_cents
      if (payload.is_configure) {
        changeCpm /= 100
      }
      state.vistarSSpData = { ...payload, cpm_floor_cents: changeCpm }
    },
    SET_TAB_STATE(state, tabState) {
      state.tabState = tabState
      localStorage.setItem('tabState', tabState)
    },

  },
  actions: {
    /* eslint-disable */
    async getDevices({ commit, rootState, rootGetters }) {
      if (rootGetters['user/getUser'].user_has_role[0].role.role === 'admin') {
        try {
          const { data: { data } } = await Vue.prototype.$axios.get(`admin/device?limit=all`)
          commit('SET_DEVICES', data.results)
        } catch (e) {
            console.log(e)
          throw new Error('No devices found')
        }
      } else {
        try {
          const { data: { data } } = await Vue.prototype.$axios.get('user/device')
          commit('SET_DEVICES', data.results)
        } catch (e) {
          console.log(e)
          throw new Error('No devices found')
        }
      }
    },
    /* eslint-enable */
    setTabState({ commit }, tabState) {
      commit('SET_TAB_STATE', tabState)
    },
    async getDeviceAdmin({ commit }, { userId, deviceId }) {
      try {
        const response = await Vue.prototype.$axios.get(`admin/device/find/${deviceId}`, {
          headers: {
            userHasGroupId: userId,
          },
        })
        if (response.data.statusCode === 200) {
          const { data } = response.data
          commit('SET_DEVICE', data)
          return true
        }
      } catch (e) {
        console.log(e)
        throw new Error('No Device found against this ID')
      }
      return false
    },
    async getUserDevice({ commit }, { id }) {
      try {
        const response = await Vue.prototype.$axios.get(`user/device/find/${id}`)
        if (response.data.statusCode === 200) {
          const { data: { data } } = response || {}
          commit('SET_DEVICE', data)
          return true
        }
      } catch (e) {
        console.log(e)
        throw new Error('No Device found against this ID')
      }
      return false
    },

    async getDeviceSSpData({ commit }, { deviceId, sspType, userId }) {
      try {
        const response = await Vue.prototype.$axios.get('admin/ssp-configure', {
          params: {
            sspType,
            deviceId,
          },
          headers: {
            userHasGroupId: userId,
          },
        })
        if (response.data.statusCode === 200) {
          const { data: { data } } = response || {}
          if (sspType === 'both') {
            commit('SET_VISTAR', data.vistar)
            commit('SET_HIVESTACK', data.hivestack)
          } else if (sspType === 'hivestack') {
            commit('SET_HIVESTACK', data)
          } else {
            commit('SET_VISTAR', data)
          }
          return true // Return true when the API call is successful.
        }
      } catch (e) {
        console.error(e)
        throw new Error('No SSP data found against this ID')
      }
      return false // Return false when an error occurs or the status is not 200.
    },
    async deviceSspOptionAdmin({ commit }, { sspOptIn, userId, deviceId }) {
      try {
        const response = await Vue.prototype.$axios.patch(`admin/device/change-ssp-opt-in/${deviceId}`, { ssp_opt_in: sspOptIn },
          {
            headers: {
              userHasGroupId: userId,
            },
          })
        if (response.data.statusCode === 200) {
          const { data: { data } } = response
          commit('SET_DEVICE', data)
          showToast('SSP Updated', response.data.message.toString(), 'success')
        }
      } catch (e) {
        console.error(e)
        showToast('Error', e.response.data.message.toString(), 'danger')
        throw new Error("Device don't get updated")
      }
    },
    async deviceSspOptionUser({ commit }, { sspOptIn, deviceId }) {
      try {
        const response = await Vue.prototype.$axios.patch(`admin/device/change-ssp-opt-in/${deviceId}`, { ssp_opt_in: sspOptIn })
        if (response.data.statusCode === 200) {
          const { data: { data } } = response
          commit('SET_DEVICE', data)
          showToast('SSP Updated', response.data.message.toString(), 'success')
        }
      } catch (e) {
        console.error(e)
        showToast('Error', e.response.data.message.toString(), 'danger')
        throw new Error('Device don,t get updated')
      }
    },
    /* eslint-disable */
    async vistarAdUpdate({ commit }, { vistarAdUpdate, deviceId, userId }) {
      try {
        const response = await Vue.prototype.$axios.put(`admin/device/ssp-ad-show/${deviceId}`, { is_vistar_ad_show: vistarAdUpdate }, { headers: { userHasGroupId: userId } })
        if (response.data.statusCode === 200) {
          const vistar= response.data.data
          commit('SET_VISTAR',vistar)
         return true
        }
      } catch (e) {
        console.error(e)
        throw new Error('Cannot update the ads')
      }
      return false
    },
    async hivestackAdUpdate({commit},{ hivestackAdUpdate,deviceId,userId }){
      try{
        const response= await Vue.prototype.$axios.put(`admin/device/ssp-ad-show/${deviceId}`,{is_hivestack_ad_show:hivestackAdUpdate},{headers:{userHasGroupId:userId}})
        if(response.data.statusCode=== 200){
          const hivestack=response.data.data
          commit('SET_HIVESTACK',hivestack)
          return true
        }
      }catch(e){
        console.error(e)
        throw new Error('Cannot update the ads')
      }
      return false
    },
    async hivestackConfigure({commit},{payload, userId}){
      try {
        const response= await Vue.prototype.$axios.post('admin/configure/hivestack',payload,{
          headers: {
            userHasGroupId: userId
          },
        })
        if(response.data.statusCode === 201) {
          const {data:{data}} = response || {}
          commit('SET_HIVESTACK',data)
        } else {
          console.error('Unexpected status code', response.data.statusCode);
          throw new Error('Failed to configure device');
        }
      } catch(e) {
        console.error("API Error", e )
        throw e
      }
    },
    async vistarConfigure({ commit }, { payload, userId }) {
      try {
        const response = await Vue.prototype.$axios.post('admin/configure/vistar', payload, {
          headers: {
            userHasGroupId: userId,
          },
        })
        if (response.data.statusCode === 201) {
          const { data: { data } } = response || {}
          commit('SET_VISTAR', data)
        }else{
          console.error('Unexpected status code:', response.data.statusCode);
          throw new Error('Failed to configure device');
        }
      } catch (e) {
        console.error("API Error",e)
        throw e
      }
    },
    async uploadImage({ commit }, { payload, userId, deviceMac }) {
      try {
        const formData = new FormData();
        formData.append('image', payload);
        const response = await Vue.prototype.$axios.post('admin/device/upload', formData, {
          headers: {
            'mac-address': deviceMac,
            userHasGroupId: userId
          }
        });
        if (response.data.statusCode === 200) {
          const { data } = response;
          commit('SET_DEVICE', data);
          return true;
        }
      } catch (error) {
        console.error('File upload failed:', error);
        throw new Error(error);
      }
      return false;
    },
    async deleteImage({ commit, rootGetters }, { url, userId, deviceMac }) {
      try {
        const response = await Vue.prototype.$axios.delete('admin/device/image',  {
          headers:{
            userHasGroupId:userId,
            'mac-address':deviceMac
          },
          data:{
            url
          }
        });
        if (response.data && response.data.statusCode === 200) {
          return true;
        } else {
          const errorMessage = response.data ? response.data.message : 'Unknown error';
          console.error('Error:', errorMessage);
          throw new Error(errorMessage);
        }
      } catch (e) {
        console.error(e);
        throw new Error(e);
      }
    },
    async updateDeviceLocationAdmin({commit} ,{payload}) {
      try{
        const response = await Vue.prototype.$axios.put(`admin/device/update-location/${payload.macAddress}`,{
          lat:payload.lat,
          lng: payload.lng
        })
        if(response.data.statusCode===200){
          const {data:{data}} = response
          commit('SET_DEVICE',data)
        }
        return response 
      }catch(e){
        console.error(e);
        showToast('Data Failed',e.response.data.message.toString(),"danger")
        throw new Error(e)
      }
    },
    async updateDeviceLocationUser({commit} ,{payload}) {
      try{
        const response = await Vue.prototype.$axios.put(`user/device/update-location/${payload.macAddress}`,{
          lat:payload.lat,
          lng: payload.lng
        })
        if(response.data.statusCode===200){
          const {data:{data}} = response
          commit('SET_DEVICE',data)
        }
        return response
      }catch(e){
        console.error(e);
        showToast('Data Failed',e.response.data.message.toString(),"danger")
        throw new Error(e)
      }
    },
  },
  getters: {
    getDevices: state => state.allDevices,
    getGroups: state => state.allGroups,
    getDevice: state => state.singleDevice,
    getMacAddress: state => state.macAddress,
    getHivestackData: state => state.hivestackSSpData,
    getVistarData: state => state.vistarSSpData,
    getDummyImages: state => state.dummyImages,
    getTabState: state => state.tabState
  },
}
