export default [
  {
    path: '/groups',
    name: 'groups',
    component: () => import('@/views/admin/AllGroups.vue'),
    meta: {
      pageTitle: 'All Groups',
      breadcrumb: [
        {
          text: 'All Groups',
          active: true,
        },
      ],
      requireAuth: true,
    },
  },
  {
    path: '/groups/:userId',
    name: 'user',
    component: () => import('@/views/admin/user/User.vue'),
    meta: {
      pageTitle: 'All Groups',
      breadcrumb: [
        {
          text: 'All Groups',
          to: '/groups',
        },
        {
          text: 'Group',
          active: true,
        },
      ],
      requireAuth: true,
    },
  },

  {
    path: '/users/:userId/user-profile',
    name: 'user-profile',
    component: () => import('@/components/user/UserProfile.vue'),
    meta: {
      pageTitle: 'All Groups',
      breadcrumb: [
        {
          text: 'All Groups',
          to: '/groups',
        },
        {
          isDynamic: true,
          key: 'userId',
          text: 'Group',
          to: '/groups/:userId',
        },
        {
          text: 'Profile',
          active: true,
        },
      ],
      requireAuth: true,
    },
  },
  {
    path: '/users/:userId/user-banner-ads',
    name: 'user-banner-ads',
    component: () => import('@/views/admin/user/UserBannerAds.vue'),
    meta: {
      pageTitle: 'All Groups',
      breadcrumb: [
        {
          text: 'All Groups',
          to: '/groups',
        },
        {
          isDynamic: true,
          key: 'userId',
          text: 'Group',
          to: '/groups/:userId',
        },
        {
          text: 'Internal Network Ads',
          active: true,
        },
      ],
      requireAuth: true,
    },
  },
  {
    path: '/users/:userId/user-group-members',
    name: 'user-group-members',
    component: () => import('@/views/admin/user/UserGroupMembers.vue'),
    meta: {
      pageTitle: 'All Groups',
      breadcrumb: [
        {
          text: 'All Groups',
          to: '/groups',
        },
        {
          isDynamic: true,
          key: 'userId',
          text: 'Group',
          to: '/groups/:userId',
        },
        {
          text: 'Group Members',
          active: true,
        },
      ],
      requireAuth: true,
    },
  },
  {
    path: '/users/:userId/user-media-storage',
    name: 'user-media-storage',
    component: () => import('@/views/admin/user/UserMediaStorage.vue'),
    meta: {
      pageTitle: 'All Groups',
      breadcrumb: [
        {
          text: 'All Groups',
          to: '/groups',
        },
        {
          isDynamic: true,
          key: 'userId',
          text: 'Group',
          to: '/groups/:userId',
        },
        {
          text: 'Media Storage',
          active: true,
        },
      ],
      requireAuth: true,
    },
  },
  {
    path: '/users/:userId/user-devices-group',
    name: 'user-devices',
    component: () => import('@/views/admin/user/UserDevices.vue'),
    meta: {
      pageTitle: 'All Groups',
      breadcrumb: [
        {
          text: 'All Groups',
          to: '/groups',
        },
        {
          isDynamic: true,
          key: 'userId',
          text: 'Group',
          to: '/groups/:userId',
        },
        {
          text: 'Devices',
          active: true,
        },
      ],
      requireAuth: true,
    },
  },
  {
    path: '/users/:userId/single-device/:deviceId/:deviceName',
    name: 'single-device-admin',
    component: () => import('@/views/devices/SingleDevice.vue'),
    meta: {
      pageTitle: '',
      breadcrumb: [
        {
          isDynamic: true,
          key: 'userId',
          text: 'Group',
          to: '/groups/:userId',
        },
        {
          text: 'Devices',
          to: '/users/:userId/user-devices-group',
          key: 'userId',
          isDynamic: true,
        },
        {
          text: '',
          active: true,
        },
      ],
      requireAuth: true,
    },
    beforeEnter(to, from, next) {
      if (to.name === 'single-device-admin') {
      /* eslint-disable */
        to.meta.pageTitle = to.params.deviceName
        to.meta.breadcrumb[2].text=to.params.deviceName
        /* eslint-enable */
        next()
      }
    },
  },
  {
    path: '/user/:userId/:deviceId/hivestack/config/:deviceName',
    name: 'device-hivestack-config',
    component: () => import('@/components/admin/HiveStackConfiguration.vue'),
    meta: {
      pageTitle: 'Hive Stack',
      breadcrumb: [
        {
          text: 'Devices',
          to: '/users/:userId/user-devices-group',
          key: 'userId',
          isDynamic: true,
        },
        {
          text: '',
          to: '/users/:userId/single-device/:deviceId/:deviceName',
          isMultiple: true,
          key: ['userId', 'deviceId', 'deviceName'],
        },
        {
          text: 'HiveStack',
          active: true,
        },
      ],
      requireAuth: true,
    },
    beforeEnter(to, from, next) {
      if (to.name === 'device-hivestack-config') {
      /* eslint-disable */
        to.meta.breadcrumb[1].text = to.params.deviceName
        /* eslint-enable */
        next()
      }
      // console.log(from)
    },
  },
  {
    path: '/user/:userId/:deviceId/vistar/config/:deviceName',
    name: 'device-vistar-config',
    component: () => import('@/components/admin/VistarConfiguration.vue'),
    meta: {
      pageTitle: 'Vistar',
      breadcrumb: [
        {
          text: 'Devices',
          to: '/users/:userId/user-devices-group',
          key: 'userId',
          isDynamic: true,
        },
        {
          text: '',
          to: '/users/:userId/single-device/:deviceId/:deviceName',
          isMultiple: true,
          key: ['userId', 'deviceId', 'deviceName'],
        },
        {
          text: 'Vistar',
          active: true,
        },
      ],
      requireAuth: true,
    },
    beforeEnter(to, from, next) {
      if (to.name === 'device-vistar-config') {
      /* eslint-disable */
        to.meta.breadcrumb[1].text = to.params.deviceName
        /* eslint-enable */
        next()
      }
      // console.log(from)
    },
  },
  {
    path: '/report/single-device/:id/:name',
    name: 'single-table-admin',
    component: () => import('@/views/SingleScreen.vue'),
    meta: {
      pageTitle: 'Single Device',
      breadcrumb: [
        {
          text: 'Report',
          to: '/report',
        },
        {
          text: '',
          active: true,
        },
      ],
      requireAuth: true,
    },
    beforeEnter(to, from, next) {
      if (to.name === 'single-table-admin') {
      /* eslint-disable */
        to.meta.pageTitle = to.params.name
        to.meta.breadcrumb[1].text =to.params.name
        /* eslint-enable */
        next()
      }
      // console.log(from)
    },
  },
]
