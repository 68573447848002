export default [
  {
    path: '/report',
    name: 'report-page',
    component: () => import('@/views/reports/index.vue'),
    meta: {
      pageTitle: 'Reports',
      breadcrumb: [
        {
          text: 'Report',
          active: true,
        },
      ],
      requireAuth: true,
    },
  },
  {
    path: '/report/single-device/:id/:name',
    name: 'single-table-user',
    component: () => import('@/views/SingleScreen.vue'),
    meta: {
      pageTitle: 'Single Device',
      breadcrumb: [
        {
          text: 'Report',
          to: '/report',
        },
        {
          text: '',
          active: true,
        },
      ],
      requireAuth: true,
    },
    beforeEnter(to, from, next) {
      if (to.name === 'single-table-user') {
      /* eslint-disable */
        to.meta.pageTitle = to.params.name
        to.meta.breadcrumb[1].text =to.params.name
        /* eslint-enable */
        next()
      }
      // console.log(from)
    },
  },
]
